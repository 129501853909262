import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["linkButton", "fileButton", "linkField", "fileField", "typeFieldValue"];

  static values = {
    type: String,
  }

  connect() {
    if (this.typeValue == "link") {
      this.selectLink();
    } else {
      this.selectFile();
    }
  }

  selectLink() {
    this.typeValue = "link";
    this.linkButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.linkButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.fileButtonTarget.classList.remove("bg-primary","text-white", "font-medium");
    this.fileButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.linkFieldTarget.classList.remove("hidden");
    this.fileFieldTarget.classList.add("hidden");

    this.typeFieldValueTarget.value = "link";
  }

  selectFile() {
    this.typeValue = "file";
    this.fileButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.fileButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.linkButtonTarget.classList.remove("bg-primary", "text-white", "font-medium");
    this.linkButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.linkFieldTarget.classList.add("hidden");
    this.fileFieldTarget.classList.remove("hidden");

    this.typeFieldValueTarget.value = "file";
  }

}