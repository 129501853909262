import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/themes/light.css";

import { French } from "flatpickr/dist/l10n/fr.js";
import { english } from "flatpickr/dist/l10n/default.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default class extends Flatpickr {

  static values = { bubbleChange: Boolean }

  locales = {
    fr: French,
    en: english,
    es: Spanish,
    "en-US": english
  };

  initialize() {
    this.config = {
      enableTime: true,
      time_24hr: this.show24format,
      locale: this.locale,
    };
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }

  get show24format() {
    return !(this.data.has("locale") && this.data.get("locale") === "en-US");
  }

  change(selectedDates, dateStr, instance) {
    if (this.bubbleChangeValue) {
      this.sendUpdatedStartTime(dateStr);
    }
  }

  sendUpdatedStartTime(startTimeStr) {
    this.element.dispatchEvent(new CustomEvent("bubbleStartTime", {
      detail: {
        startTime: startTimeStr
      },
      bubbles: true
    }));
  }

  handleMessage(ev) {
    var startTime = this.fp.parseDate(ev.detail.startTime, "Y-m-d h:i");
    startTime.setHours(startTime.getHours() + 2)
    this.fp.setDate(startTime, true);
  }
}