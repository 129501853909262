import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputList", "input"]

  select(event) {
    this.inputListTarget.querySelectorAll('.task-status-input').forEach(element => {
      element.classList.remove("shadow-md", "border-solid", "border-2", "border-grey-400");
      element.classList.add("opacity-50");
    });
    const status = event.currentTarget.dataset.status;
    event.currentTarget.classList.remove("opacity-50");
    event.currentTarget.classList.add("shadow-md", "border-solid", "border-2", "border-grey-400");
    this.inputTarget.value = status;
  }
}