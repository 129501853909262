import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "suggestions", "selectedItems", "users"]

  static values = {
    searchedItems: Array,
    selectedItems: Array
  }

  connect() {
    for (var i = 0; i < this.selectedItemsValue.length; i++) {
      this.selectedItemsTarget
        .insertAdjacentHTML(
          "beforeend",
          `
          <div class="px-3 py-2 flex rounded-lg bg-gray-200" data-id="${this.selectedItemsValue[i].id}" data-cy="selected-user">
            <div class="w-8 h-8 flex justify-center content-center rounded overflow-hidden mr-2">
              <img class="object-cover object-center w-full h-full" src="${this.selectedItemsValue[i].image}">
            </div>
            <span class="flex items-center flex-1">${this.selectedItemsValue[i].label}</span>
            <div class="w-8 h-8 flex justify-center items-center rounded cursor-pointer hover:bg-gray-300" data-id="${this.selectedItemsValue[i].id}" data-action="click->autocomplete#delete" data-cy="delete-user">
              <i class="fas fa-times"></i>
            </div>
          </div>
        `);
    }

  }

  autocomplete(event) {
    const searchedTerms = this.searchInputTarget.value;
    const results = this.searchedItemsValue
      .filter(searchedItem => {
        return (searchedItem.searchable)
          .toLowerCase()
          .includes(searchedTerms.toLowerCase());
      })
      .slice(0, 6);
    this.render(searchedTerms ? results : []);
  }

  render(results) {
    if (results.length > 0) {
      this.suggestionsTarget.classList.remove("hidden");
      this.suggestionsTarget.innerHTML = results.reduce((html, searchedItem) => {
        return (html += `
        <li class="px-3 py-2 w-full flex hover:bg-gray-200 cursor-pointer" data-id="${searchedItem.id}" data-label="${searchedItem.label}" data-image="${searchedItem.image}" data-action="click->autocomplete#select">
          <span class="name">
            ${searchedItem.label}
          </span>
        </li>`);
      }, "");
    } else {
      this.suggestionsTarget.classList.add("hidden");
    }
  }

  select(event) {
    const searchedItemId = event.currentTarget.dataset.id;
    const searchedItemLabel = event.currentTarget.dataset.label;
    const searchedItemImage = event.currentTarget.dataset.image;
    if (searchedItemId !== null && searchedItemId != undefined) {
      this.suggestionsTarget.classList.add("hidden");
      this.searchInputTarget.value = "";
      this.suggestionsTarget.innerHTML = "";
      var selectInput = document.querySelector(".user-ids-input");
      var alreadySelected = false;
      var length = selectInput.options.length;
      for (var i = 0; i < length; i++) {
        if (selectInput.options[i].value == searchedItemId) {
          if (selectInput.options[i].selected == true) {
            alreadySelected = true;
          } else {
            selectInput.options[i].selected = true;
          }
        }
      }
      if (alreadySelected == false) {
        this.selectedItemsTarget
          .insertAdjacentHTML(
            "beforeend",
            `
          <div class="px-3 py-2 flex rounded-lg bg-gray-200" data-id="${searchedItemId}" data-cy="selected-user">
            <div class="w-8 h-8 flex justify-center content-center rounded overflow-hidden mr-2">
              <img class="object-cover object-center w-full h-full" src="${searchedItemImage}">
            </div>
            <span class="flex items-center flex-1">${searchedItemLabel}</span>
            <div class="w-8 h-8 flex justify-center items-center rounded cursor-pointer hover:bg-gray-300" data-id="${searchedItemId}" data-action="click->autocomplete#delete" data-cy="delete-user">
              <i class="fas fa-times"></i>
            </div>
          </div>
        `);
      }
    }
  }

  delete(event) {
    const deletedItemId = event.currentTarget.dataset.id;
    if (deletedItemId !== null && deletedItemId != undefined) {
      var selectInput = document.querySelector(".user-ids-input");
      var length = selectInput.options.length;
      for (var i = 0; i < length; i++) {
        if (selectInput.options[i].value == deletedItemId) {
          selectInput.options[i].selected = false;
        }
      }
      this.selectedItemsTarget.querySelector(`[data-id="${deletedItemId}"]`).remove();
    }
  }

}