import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "output", "input" ]

  buildAcronym() {
    var inputValue = this.inputTarget.value;
    if (inputValue !== null && inputValue !== undefined) {
      var matches = inputValue.match(/\b(\w)/g);
      var acronym = matches.join('');
      this.outputTarget.value = acronym.toUpperCase();
    }
  }

}