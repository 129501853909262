import { Controller } from "@hotwired/stimulus";
import Quill from "quill";
import QuillMarkdown from "quilljs-markdown";
import MagicUrl from "quill-magic-url";
import mention from "@chafouin/quill-mention";

export default class extends Controller {

  static targets = ["container", "input"];

  static values = {
    inline: Boolean,
    jwt: String
  }

  connect() {
    Quill.register("modules/QuillMarkdown", QuillMarkdown, true);
    Quill.register("modules/magicUrl", MagicUrl);
    const jwt = this.jwtValue;
    const hasMentions = jwt !== null && jwt !== undefined && jwt !== "";
    const quill = new Quill(this.containerTarget, {
      modules: {
        toolbar: false,
        QuillMarkdown: {},
        magicUrl: true,
        keyboard: {
          bindings: {
            shift_enter: {
              key: 13,
              shiftKey: true,
              handler: (range, ctx) => {
                quill.insertText(range.index, '\n');
              }
            },
            enter: {
              key: 13,
              handler: (range, ctx) => {
                if (this.inlineValue) {
                  this.inputTarget.closest("form").requestSubmit();
                } else {
                  quill.insertText(range.index, '\n');
                }
              }
            }
          }
        },
        mention: {
          allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@"],
          source: function (searchTerm, renderList, mentionChar) {
            if (hasMentions) {
              fetch("/private_api/member_mentions?jwt=" + jwt + "&query=" + searchTerm)
                .then(function (response) {
                  return response.json();
                })
                .then(function (data) {
                  renderList(data, searchTerm);
                })
                .catch(function (error) {
                  console.error("Error fetching mentioned users:", error);
                });
            }
          },
        }

      },
      readOnly: false,
      theme: "snow",
      formats: this.buildFormats()
    });

    const input = this.inputTarget;

    if (input.value !== null && input.value !== undefined && input.value !== "") {
      quill.root.innerHTML = input.value;
    }

    quill.on("text-change", function() {
      input.value = (quill.getText().trim().length === 0) ? "" : quill.root.innerHTML;
    });

    this.quillObject = quill;
  }

  buildFormats() {
    if (this.inlineValue) {
      return [
        "bold",
        "italic",
        "list",
        "link",
        "mention"
      ]
    } else {
      return [
        "bold",
        "italic",
        "list",
        "link",
        "header",
        "mention"
      ]
    }
  }

  handleChange(event) {
    this.quillObject.root.innerHTML = event.detail.content;
  }

}