import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["start", "end"]

  sendStartTime(startTime) {
    this.endTarget.dispatchEvent(new CustomEvent("sendStartTime", {
      detail: {
        startTime: startTime
      }
    }));
  }

  handleMessage(ev) {
    let message = ev.detail.startTime;
    this.sendStartTime(ev.detail.startTime);
  }
}