import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["button", "content"];

  expand() {
    this.buttonTarget.classList.add("hidden");
    this.contentTarget.classList.remove("hidden");
  }

}