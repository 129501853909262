import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {

  static targets = ["content"]

  connect(){
    this.element.classList.remove("hidden");
    useClickOutside(this);
  }

  close() {
    this.element.classList.add("hidden");
    this.contentTarget.remove();
  }

  clickOutside(event) {
    this.close();
  }

}