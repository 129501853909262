import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["value", "input"];

  display(evt) {
    const fileName = evt.target.value.split('\\').pop();

    if (this.valueTarget.nodeName == 'INPUT') {
      this.valueTarget.placeholder = fileName;
    } else {
      this.valueTarget.innerHTML = fileName;
    }
  }

}