import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["picker", "input"];

  initialize() {
    this.initPicker();
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,
      comparison: false,
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: false,
          clear: false,
          save: false,
        },
      },
    });



    this.picker.on('change', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();
    });
  }

  setColor() {
    const color = this.inputTarget.value
    if (/^#[0-9A-F]{6}$/i.test(color)) {
      this.picker.setColor(color);
    }
  }

  show() {
    this.picker.show();
  }

}