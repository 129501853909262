import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  preventSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

}