import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  disconnect() {
    this.hide();
  }

  show() {
    this.contentTarget.classList.remove("hidden");
  }

  hide() {
    this.contentTarget.classList.add("hidden");
  }

}