import { Controller } from "@hotwired/stimulus";
import {useClickOutside} from "stimulus-use";

export default class extends Controller {
  static targets = ["base", "edit"];

  connect() {
    console.log("connect edit toggle");
  }

  edit() {
    console.log("edit");
    this.editTarget.classList.remove("hidden");
    this.baseTarget.classList.add("hidden");
  }

  cancel() {
    console.log("cancel");
    this.editTarget.classList.add("hidden");
    this.baseTarget.classList.remove("hidden");
  }

}