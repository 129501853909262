import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["menu", "source"];

  static values = {
    useClickOutside: Boolean
  }

  connect() {
    if (this.useClickOutsideValue) {
      useClickOutside(this);
    }
  }

  toggle(event) {
    const container = document.getElementById('sidebar-more-menu-container');

    if (this.menuTarget.classList.contains("hidden")) {
      this._showMenu(container);

    } else {
      this._hideMenu(container);
    }
  }

  close() {
    const container = document.getElementById('sidebar-more-menu-container');
    this._hideMenu(container);
  }

  closeWithEscape(event) {
    if (event.keyCode == 27) {
      this.close();
    }
  }

  closeWithClickOutside(event) {
    if (event.srcElement.id === 'sidebar-more-menu-container') {
      this.close();
    }
  }


  _showMenu(container) {
    container.classList.remove('hidden');
    container.classList.add('block');

    this.menuTarget.classList.remove('hidden');
    this.menuTarget.classList.add('flex', 'flex-col');
    const top = this.sourceTarget.getBoundingClientRect().top - (this.menuTarget.getBoundingClientRect().height / 2) - 40;
    this.menuTarget.style.top = `${top}px`;
  }

  _hideMenu(container) {
    container.classList.add('hidden');
    container.classList.remove('block');
    this.menuTarget.classList.add('hidden');
    this.menuTarget.classList.remove('flex', 'flex-col');
  }
}