import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  static values = {
    toggled: Array,
    initial: String
  }

  connect() {
    if (!this.toggledValue.includes(this.initialValue)) {
      this.contentTarget.classList.add("hidden");
    }
  }

  handleChange(event) {
    const newValue = event?.target?.value;

    if (newValue !== null && newValue !== undefined && this.toggledValue.includes(newValue)) {
      this.contentTarget.classList.remove("hidden");
    } else {
      this.contentTarget.classList.add("hidden");
    }
  }

}