import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('keydown', this.preventEnter.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('keydown', this.preventEnter.bind(this))
  }

  preventEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }
}
