import { Controller } from "@hotwired/stimulus";
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

export default class extends Controller {
  static targets = ["calendar", "previousMonth", "nextMonth", "currentMonth", "filter"]

  static values = {
    jwt: String,
    locale: String
  }

  connect(){
    let jwt = this.jwtValue;
    let filter = this.filterTarget;
    let calendar = new Calendar(this.calendarTarget, {
      locale: this.localeValue,
      firstDay: 1,
      fixedWeekCount: false,
      eventSources: {
        url: '/private_api/calendar',
        method: 'GET',
        extraParams: function() {
          return {
            jwt: jwt,
            team_id: filter.value
          };
        }
      },
      nowIndicator: true,
      plugins: [dayGridPlugin,timeGridPlugin],
      headerToolbar: false,
      datesSet: event => {
        let midDate = new Date((event.start.getTime() + event.end.getTime()) / 2);
        this.currentMonthTarget.innerHTML = midDate.toLocaleDateString(this.localeValue, { year: 'numeric', month: 'long' });
      },
      eventDidMount: function(data) {
        data.el.setAttribute("data-turbo", false);
      },
      eventContent: function (arg) {
        var icon = "";
        var colors = "";

        if (arg.event.extendedProps.eventType === "game") {
          icon = "trophy";
        } else if (arg.event.extendedProps.eventType === "training") {
          icon = "dumbbell";
        } else {
          icon = "calendar";
        }

        for (var index in arg.event.extendedProps.colors) {
          colors += '<div class="flex-1" style="background: ' + arg.event.extendedProps.colors[index] + ';"></div>'
        }

        return {
          html:
            '<div class="bg-secondary-light rounded w-full text-black overflow-hidden flex">' +
              '<div class="calendar-event-colors flex flex-col">' + colors + '</div>' +
              '<div class="calendar-event-title py-1 px-2 items-center">' +
                '<i class="fas fa-' + icon + ' mr-2"></i>' +
                '<span>' +  arg.event.title + '</span>' +
              '</div>' +
            '</div>'
        }
      }
    })

    this.previousMonthTarget.addEventListener("click", function() {
      calendar.prev();
    });

    this.nextMonthTarget.addEventListener("click", function() {
      calendar.next();
    });

    this.filterTarget.addEventListener("change", function() {
      calendar.refetchEvents();
    });

    calendar.render();
  }

}
