import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["content", "source"];

  static values = {
    enabled: Boolean,
    useClickOutside: Boolean
  }

  connect() {
    if (this.enabledValue) {
      if (this.useClickOutsideValue) {
        useClickOutside(this);
      }
      this.boundShow = this.show.bind(this)
      this.sourceTarget.addEventListener("long-press", this.boundShow);
    }
  }

  disconnect() {
    // this.hide();
    this.sourceTarget.removeEventListener("click", this.boundShow);
  }

  show(event) {
    event.preventDefault();
    this.contentTarget.classList.remove("hidden");
  }

  hide() {
    this.contentTarget.classList.add("hidden");
  }

  clickOutside(event) {
    // event.preventDefault();
    this.contentTarget.classList.add("hidden");
  }

}