import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loader", "label"]

  static values = {
    callbackEventName: String
  }

  start() {
    this.setLoading();
  }

  setLoading() {
    this.loaderTarget.parentNode.disabled = true;
    this.loaderTarget.classList.remove("hidden");
    this.loaderTarget.classList.add("block");
    this.labelTarget.classList.add("hidden");
  }

  onSuccess(event) {
    if (event.detail[1] === "No Content" || event.detail[1] === "OK") {
      const successSubmitEvent = new CustomEvent(this.callbackEventNameValue);
      window.dispatchEvent(successSubmitEvent);
    }
  }
}