import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["content"];

  hide() {
    this.contentTarget.classList.add("hidden");
  }

}