import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  static values = {
    toggled: String,
    initial: String
  }

  connect() {
    if (this.initialValue !== this.toggledValue) {
      this.contentTarget.classList.add("hidden");
    }
  }

  handleChange(event) {
    if (event?.target?.value === this.toggledValue) {
      this.contentTarget.classList.remove("hidden");
    } else {
      this.contentTarget.classList.add("hidden");
    }
  }

}