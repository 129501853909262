import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"]

  initialize() {
    this.submitTarget.disabled = true;
  }

  toggle() {
    if (this.submitTarget.disabled) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true;
    }
  }

}