import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchInput", "result"]

  static values = {
    ready: Boolean,
    submitOnEnter: Boolean,
    url: String,
    minLength: Number,
    delay: { type: Number, default: 300 },
    queryParam: { type: String, default: "q" }
  }

  autocomplete(event) {
    const searchedTerms = this.searchInputTarget.value;
    if (searchedTerms && searchedTerms.length >= this.minLengthValue) {
      this.fetchResult(searchedTerms);
    } else {
    }
  }

  fetchResult = async (query) => {
    if (!this.hasUrlValue) return

    const url = this.buildURL(query)
    try {
      this.element.dispatchEvent(new CustomEvent("loadstart"))
      const html = await this.doFetch(url)
      this.replaceResult(html)
      this.element.dispatchEvent(new CustomEvent("load"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
    } catch(error) {
      this.element.dispatchEvent(new CustomEvent("error"))
      this.element.dispatchEvent(new CustomEvent("loadend"))
      throw error
    }
  }

  replaceResult(html) {
    if (html !== "") {
      this.resultTarget.innerHTML = html;
      this.showResults();
    } else {
      this.hideResults();
    }
  }

  buildURL(query) {
    const url = new URL(this.urlValue, window.location.origin);
    const params = new URLSearchParams(url.search.slice(1));
    params.append(this.queryParamValue, query);
    url.search = params.toString();

    return url.toString();
  }

  doFetch = async (url) => {
    const response = await fetch(url, this.optionsForFetch())

    if (!response.ok) {
      throw new Error(`Server responded with status ${response.status}`)
    }

    const html = await response.text()
    return html
  }

  optionsForFetch() {
    return { headers: { "X-Requested-With": "XMLHttpRequest" } }
  }

  showResults() {
    this.resultTarget.classList.remove("hidden");
  }

  hideResults() {
    this.resultTarget.classList.add("hidden");
  }

}