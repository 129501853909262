import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["internalButton", "externalButton", "internalForm", "externalForm", "typeFieldValue"];

  static values = {
    type: String,
  }

  connect() {
    if (this.typeValue == "internal") {
      this.selectInternal();
    } else {
      this.selectExternal();
    }
  }

  selectInternal() {
    this.typeValue = "internal";
    this.internalButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.internalButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.externalButtonTarget.classList.remove("bg-primary","text-white", "font-medium");
    this.externalButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.internalFormTarget.classList.remove("hidden");
    this.externalFormTarget.classList.add("hidden");

    this.typeFieldValueTarget.value = "internal";
  }

  selectExternal() {
    this.typeValue = "external";
    this.externalButtonTarget.classList.add("bg-primary", "text-white", "font-medium");
    this.externalButtonTarget.classList.remove("bg-secondary-light", "text-gray-500", "text-black");
    this.internalButtonTarget.classList.remove("bg-primary", "text-white", "font-medium");
    this.internalButtonTarget.classList.add("bg-secondary-light", "text-black");

    this.internalFormTarget.classList.add("hidden");
    this.externalFormTarget.classList.remove("hidden");

    this.typeFieldValueTarget.value = "external";
  }

}