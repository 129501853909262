import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

/*
 * Usage
 * =====
 *
 * add data-controller="empty-state" to common ancestor
 *
 * Targets:
 * data-empty-state-target="emptyMessage"
 * data-empty-state-target="list"
 *
 */
export default class extends Controller {
  static targets = ["list", "container", "emptyMessage"];

  connect() {
    useMutation(this, {
      element: this.listTarget,
      childList: true,
    });

    if (this.listTarget.children.length > 0) {
      this.emptyMessageTarget.classList.add("hidden");
      if (this.hasContainerTarget) {
        this.containerTarget.classList.remove("hidden");
      }
    } else {
      this.emptyMessageTarget.classList.remove("hidden");
      if (this.hasContainerTarget) {
        this.containerTarget.classList.add("hidden");
      }
    }
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (mutation.type === "childList") {
        if (this.listTarget.children.length > 0) {
          this.emptyMessageTarget.classList.add("hidden");
          if (this.hasContainerTarget) {
            this.containerTarget.classList.remove("hidden");
          }
        } else {
          this.emptyMessageTarget.classList.remove("hidden");
          if (this.hasContainerTarget) {
            this.containerTarget.classList.add("hidden");
          }
        }
      }
    }
  }
}