import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["modal", "background"]

  connect(){
    document.getElementById("layout").style.filter = "blur(1px)";
    this.element.classList.remove("hidden");
    this.modalTarget.style.backgroundColor = "#b3b3b33f";
  }

  close() {
    document.getElementById("layout").style.filter = null;
    this.element.classList.add("hidden");
    // this.element.remove();
    this.element.parentElement.removeAttribute("src");
    this.modalTarget.remove();
  }

  closeWithEscape(event) {
    if (event.keyCode == 27) {
      this.close();
    }
  }

  closeWithClickOutside(e) {
    // if (e && this.modalTarget.contains(e.target)) {
    //   return
    // }
    // this.close();
  }

  clickOutside(event) {
    // TODO check when autocomplete
    // this.close();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

}