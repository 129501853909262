import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["active", "injured", "inactive", "activeAvailabilities", "injuredAvailabilities", "inactiveAvailabilities"];

  handleChange(event) {
    const newStatus = event?.target?.value;
    if (newStatus === "active") {
      this.activeTarget.classList.remove("hidden");
      this.injuredTarget.classList.add("hidden");
      this.inactiveTarget.classList.add("hidden");
      this.activeAvailabilitiesTarget.classList.remove("hidden");
      this.injuredAvailabilitiesTarget.classList.add("hidden");
      this.inactiveAvailabilitiesTarget.classList.add("hidden");
    } else if (newStatus === "injured") {
      this.activeTarget.classList.add("hidden");
      this.injuredTarget.classList.remove("hidden");
      this.inactiveTarget.classList.add("hidden");
      this.activeAvailabilitiesTarget.classList.add("hidden");
      this.injuredAvailabilitiesTarget.classList.remove("hidden");
      this.inactiveAvailabilitiesTarget.classList.add("hidden");
    } else if (newStatus === "inactive") {
      this.activeTarget.classList.add("hidden");
      this.injuredTarget.classList.add("hidden");
      this.inactiveTarget.classList.remove("hidden");
      this.activeAvailabilitiesTarget.classList.add("hidden");
      this.injuredAvailabilitiesTarget.classList.add("hidden");
      this.inactiveAvailabilitiesTarget.classList.remove("hidden");
    }
  }

}