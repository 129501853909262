import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.observeMutations(this.sortChildren)
  }

  // Private

  sortChildren() {
    const { children } = this
    if (elementsAreSorted(children)) return
    children.sort(compareElements).forEach(this.append)
  }

  get children() {
    return Array.from(this.element.children)
  }

  append = child => this.element.append(child)
}

function elementsAreSorted([ left, ...rights ]) {
  for (const right of rights) {
    if (compareElements(left, right) > 0) return false
    left = right
  }
  return true
}

function compareElements(left, right) {
  return getSortCode(right) - getSortCode(left)
}

function getSortCode(element) {
  return element.getAttribute("data-sort-code") || 0
}